import Simple from '@/components/task-templates/Simple'
import FloatCounter from './FloatCounter'
import Sequence from './Sequence'

export default [
  {
    component: Simple,
    name: '[0, n)',
    text: 'Дано число \\(n\\). Выведите числа от \\(0\\) до \\(n\\) (не включая \\(n\\))',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '0'
      },
      {
        input: '3',
        output: '0\n1\n2'
      }
    ]
  },
  {
    component: Simple,
    name: '[1, n]',
    text: 'Дано число \\(n\\). Выведите числа от \\(1\\) до \\(n\\) (включая \\(n\\))',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '3',
        output: '1\n2\n3'
      }
    ]
  },
  {
    component: Simple,
    name: 'N раз 1',
    text: 'Дано число \\(n\\). Выведите \\(n\\) раз число \\(1\\)',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '3',
        output: '1\n1\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'N раз N',
    text: 'Дано число \\(n\\). Выведите \\(n\\) раз число \\(n\\)',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '3',
        output: '3\n3\n3'
      }
    ]
  },
  {
    component: Simple,
    name: '[1, n] наоборот',
    text: 'Дано число \\(n\\). Выведите числа от \\(n\\) (включительно) до \\(1\\) (включительно)',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '3',
        output: '3\n2\n1'
      }
    ]
  },
  {
    component: Simple,
    name: '[0, n) наоборот',
    text: 'Дано число \\(n\\). Выведите числа от \\(n\\) (не включительно) до \\(0\\) (включительно)',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '0'
      },
      {
        input: '3',
        output: '2\n1\n0'
      }
    ]
  },
  {
    component: Simple,
    name: '[1, n] с шагом 2',
    text: 'Дано число \\(n\\). Выведите числа от \\(1\\) (включительно) до \\(n\\) (включительно) с шагом \\(2\\)',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: '1'
      },
      {
        input: '3',
        output: '1\n3'
      },
      {
        input: '6',
        output: '1\n3\n5'
      }
    ]
  },
  {
    component: Simple,
    name: '[2, n] с шагом 2',
    text: 'Дано число \\(n\\). Выведите числа от \\(2\\) (включительно) до \\(n\\) (включительно) с шагом \\(2\\)',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0',
        output: ''
      },
      {
        input: '1',
        output: ''
      },
      {
        input: '3',
        output: '2'
      },
      {
        input: '6',
        output: '2\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: '[a, b]',
    text: 'Даны числа \\(a\\) и \\(b\\) (\\(a \\le b\\)). Выведите числа от \\(a\\) (включительно) до \\(b\\) (включительно)',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0, 1',
        output: '0\n1'
      },
      {
        input: '5, 7',
        output: '5\n6\n7'
      },
      {
        input: '10, 10',
        output: '10'
      }
    ]
  },
  {
    component: Simple,
    name: '(a, b) наоборот',
    text: 'Даны числа \\(a\\) и \\(b\\) (\\(a \\le b\\)). Выведите числа от \\(b\\) (не включительно) до \\(a\\) (не включительно)',
    note: null,
    input: '\\(a\\), \\(b\\) - целые',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '0, 1',
        output: ''
      },
      {
        input: '5, 7',
        output: '6'
      },
      {
        input: '10, 15',
        output: '14\n13\n12\n11'
      }
    ]
  },
  {
    component: Simple,
    name: '[0, n) наоборот с шагом 2',
    text: 'Дано целое число \\(n\\). Выведите числа от \\(n\\) (не включительно) до \\(0\\) (включительно) c шагом 2',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '1',
        output: '0'
      },
      {
        input: '3',
        output: '2\n0'
      },
      {
        input: '4',
        output: '3\n1'
      }
    ]
  },
  {
    component: Simple,
    name: '(0, n] наоборот с шагом 2',
    text: 'Дано целое число \\(n\\). Выведите числа от \\(n\\) (включительно) до \\(0\\) (не включительно) c шагом 2',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '1',
        output: '1'
      },
      {
        input: '3',
        output: '3\n1'
      },
      {
        input: '4',
        output: '4\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Килограммы конфет',
    text: 'Дано вещественное число \\(p\\) - цена 1 кг конфет, а также целое число \\(n\\). Выведите стоимость от 1 до \\(n\\) (включительно) кг конфет',
    note: null,
    input: '\\(p\\) - вещественное, \\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '300, 3',
        output: '300\n600\n900'
      },
      {
        input: '199.75, 4',
        output: '199.75\n399.5\n599.25\n799'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 0 и 1',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(0\\) и \\(1\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '0\n1'
      },
      {
        input: '5',
        output: '0\n1\n0\n1\n0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 0, 1, 2',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(0\\), \\(1\\) и \\(2\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '0\n1'
      },
      {
        input: '5',
        output: '0\n1\n2\n0\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 1, 2',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(1\\) и \\(2\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '1\n2'
      },
      {
        input: '5',
        output: '1\n2\n1\n2\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 1, 2, 3',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(1\\), \\(2\\) и \\(3\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '1\n2'
      },
      {
        input: '5',
        output: '1\n2\n3\n1\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 1, 0',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(1\\) и \\(0\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '1\n0'
      },
      {
        input: '5',
        output: '1\n0\n1\n0\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 2, 1, 0',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(2\\), \\(1\\) и \\(0\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '2\n1'
      },
      {
        input: '5',
        output: '2\n1\n0\n2\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 0, 2, 4',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(0\\), \\(2\\) и \\(4\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '0\n2'
      },
      {
        input: '5',
        output: '0\n2\n4\n0\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 2, 4, 6',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(2\\), \\(4\\) и \\(6\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '2\n4'
      },
      {
        input: '5',
        output: '2\n4\n6\n2\n4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 1, -1',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(1\\) и \\(-1\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '1\n-1'
      },
      {
        input: '5',
        output: '1\n-1\n1\n-1\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 1, -2, 3, -1, 2, -3',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(1\\), \\(2\\) и \\(3\\), но каждое второе число отрицательное',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '1\n-2'
      },
      {
        input: '6',
        output: '1\n-2\n3\n-1\n2\n-3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чередование 3, 7',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, чередуя \\(3\\) и \\(7\\)',
    note: 'if не использовать',
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '2',
        output: '3\n7'
      },
      {
        input: '5',
        output: '3\n7\n3\n7\n3'
      }
    ]
  },
  {
    component: Simple,
    name: '[2, n], делящиеся на 2 или 3',
    text: 'Дано целое число \\(n\\). Выведите числа от 2 до \\(n\\) (включительно), которые делятся на 2 или на 3',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '3',
        output: '2\n3'
      },
      {
        input: '6',
        output: '2\n3\n4\n6'
      }
    ]
  },
  {
    component: Simple,
    name: '[2, n], делящиеся на 2 или 3 наоборот',
    text: 'Дано целое число \\(n\\). Выведите числа от 2 до \\(n\\) (включительно), которые делятся на 2 или на 3 в обратном порядке',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '5',
        output: '4\n3\n2'
      },
      {
        input: '6',
        output: '6\r\n4\r\n3\r\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Множественные ветвления в цикле',
    text: 'Даны целые числа \\(a\\) и \\(b\\) (\\(a \\le b\\)). Выведите числа от \\(a\\) до \\(b\\) включительно по порядку, но рядом с делящимися на 2 выведите 2, рядом с делящимися на 3 выведите 3, а рядом с делящимися и на 2, и на 3 выведите 2 и 3 (через пробел)',
    note: null,
    input: '\\(a\\) и \\(b\\) - целые',
    output: 'Строки с числами',
    examples: [
      {
        input: '1, 7',
        output: '1\n2 2\n3 3\n4 2\n5\n6 2 3\n7'
      },
      {
        input: '8, 15',
        output: '8 2\n9 3\n10 2\n11\n12 2 3\n13\n14 2\n15 3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Растущая сумма',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел таких, что первое число - это \\(1\\), второе - \\(1+2\\), третье \\(1+2+3\\) и т. д',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '3',
        output: '1\n3\n6'
      },
      {
        input: '5',
        output: '1\n3\n6\n10\n15'
      }
    ]
  },
  {
    component: Simple,
    name: 'Факториалы',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел таких, что первое число - это \\(1\\), второе - \\(1*2\\), третье \\(1*2*3\\) и т. д',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '3',
        output: '1\n2\n6'
      },
      {
        input: '5',
        output: '1\n2\n6\n24\n120'
      }
    ]
  },
  {
    component: Simple,
    name: 'Пары слагаемых',
    text: 'Дано целое число \\(n\\). Выведите все пары положительных чисел, которые в сумме дают n. Сначала меньшее число, затем большее',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждая пара через пробел на новой строке',
    examples: [
      {
        input: '8',
        output: '1 7\n2 6\n3 5\n4 4'
      },
      {
        input: '7',
        output: '1 6\n2 5\n3 4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Растущие числа',
    text: 'Даны числа \\(a\\) и \\(b\\) (\\(a \\le b\\)). За сколько шагов \\(a\\) станет больше \\(b\\), если на каждом шаге увеличивать \\(a\\) в 3 раза, а \\(b\\) в 2 раза?',
    note: 'Здесь лучше использовать while',
    input: '\\(a\\) и \\(b\\) - целые положительные числа',
    output: 'Количество шагов',
    examples: [
      {
        input: '1, 1',
        output: '1'
      },
      {
        input: '2, 3',
        output: '2'
      },
      {
        input: '3, 15',
        output: '4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Подобие Фибоначчи',
    text: 'Даны числа \\(a\\) и \\(b\\) - первые два числа последовательности. Каждое следующее число последовательности является суммой двух предыдущих. Выведите все числа последовательности, не превосходящие \\(a * b\\)',
    input: '\\(a\\) и \\(b\\) - целые положительные числа',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '1, 1',
        output: '1\n1'
      },
      {
        input: '2, 3',
        output: '2\n3\n5'
      },
      {
        input: '3, 5',
        output: '3\n5\n8\n13'
      }
    ]
  },
  {
    component: Sequence,
    label: '!'
  },
  {
    component: Simple,
    name: 'Вывод последовательности',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите все элементы последовательности',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Каждый элемент на новой строке',
    note: null,
    examples: [
      {
        input: 'seq(4, 1, 2)',
        output: '4\n1\n2'
      },
      {
        input: 'seq(777)',
        output: '777'
      },
      {
        input: 'seq(-1, -2, -3)',
        output: '-1\n-2\n-3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма последовательности',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите сумму её элементов',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Сумма',
    note: null,
    examples: [
      {
        input: 'seq(4, -1, 2)',
        output: '5'
      },
      {
        input: 'seq(777)',
        output: '777'
      },
      {
        input: 'seq(-1, -2, -3)',
        output: '-6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Среднее арифметическое элементов последовательности',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите среднее арифметическое из её элементов',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Среднее арифметическое',
    note: null,
    examples: [
      {
        input: 'seq(4, 2, 6)',
        output: '4'
      },
      {
        input: 'seq(4, 3, -1)',
        output: '2'
      },
      {
        input: 'seq(7, 1, 1, 1)',
        output: '2.5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Максимум',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите наибольший из её элементов',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Максимум',
    note: null,
    examples: [
      {
        input: 'seq(4, 2, 6)',
        output: '6'
      },
      {
        input: 'seq(-4, -3)',
        output: '-3'
      },
      {
        input: 'seq(7, 1, 1)',
        output: '7'
      }
    ]
  },
  {
    component: Simple,
    name: 'Номер первого минимума',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите порядковый номер первого из наименьших её элементов',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Номер первого минимума',
    note: null,
    examples: [
      {
        input: 'seq(4, 5, 1, 6)',
        output: '3'
      },
      {
        input: 'seq(-3, -4)',
        output: '2'
      },
      {
        input: 'seq(3, 7, 3)',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Номер последнего максимума',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите порядковый номер последнего из наибольших её элементов',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Номер последнего максимума',
    note: null,
    examples: [
      {
        input: 'seq(4, 5, 1, 6)',
        output: '4'
      },
      {
        input: 'seq(-3, -4)',
        output: '1'
      },
      {
        input: 'seq(3, 7, 3, 7)',
        output: '4'
      }
    ]
  },
  {
    component: Simple,
    name: 'До нуля',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите все элементы, которые стоят до первого нуля в последовательности. Гарантируется, что в последовательности есть хотя бы один ноль',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'seq(1, 2, 0, 3, 0)',
        output: '1\n2'
      },
      {
        input: 'seq(3, 2, 1, 0)',
        output: '3\n2\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Прерывание по сумме',
    text: 'Дана целочисленная последовательность \\(a\\) и число \\(s\\). Выводите элементы до тех пор, пока общая сумма меньше \\(s\\). Гарантируется, что в последовательность не закончится до набора нужной суммы',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел, \\(s\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'seq(1, 2, 0, 3, 0), 5',
        output: '1\n2\n0'
      },
      {
        input: 'seq(3, -2, 2, 1), 4',
        output: '3\n-2\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Прерывание по паре одинаковых элементов',
    text: 'Дана целочисленная последовательность \\(a\\). Выводите элементы до тех пор, пока очередной элемент не будет равен предыдущему. Гарантируется, что в последовательности есть хотя бы одна пара одинаковых соседей',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'seq(1, 2, 0, 0, 3)',
        output: '1\n2\n0'
      },
      {
        input: 'seq(3, 2, 1, 2, 2, 3)',
        output: '3\n2\n1\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Проход с пропусками нулей',
    text: 'Дана целочисленная последовательность \\(a\\). Выводите элементы до тех пор, пока очередной элемент не будет равен предыдущему, при этом пропуская нули. Гарантируется, что в последовательности есть хотя бы одна пара одинаковых соседей, если пропускать нули',
    note: 'Для пропусков использовать continue',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'seq(1, 2, 0, 0, 3, 3)',
        output: '1\n2\n3'
      },
      {
        input: 'seq(3, 0, 2, 0, 1, 0, 1)',
        output: '3\n2\n1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Проход с пропусками по условию',
    text: 'Дана целочисленная последовательность \\(a\\). Выводите элементы, начиная со второго элемента, до тех пор, пока сумма двух соседей меньше первого элемента последовательности, пропуская элементы, которые больше первого. Гарантируется, что в последовательности есть хотя бы одна пара соседей, сумма которых не меньше первого элемента, если пропускать элементы, большие первого',
    note: 'Для пропусков использовать continue',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'seq(5, 2, 2, 1, 8, 3, 4, 0)',
        output: '2\n2\n1\n3'
      },
      {
        input: 'seq(10, 2, 12, 6, 17, 8, 9)',
        output: '2\n6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Наличие отрицательного числа',
    text: 'Дана целочисленная последовательность \\(a\\). Есть ли в ней хотя бы одно отрицательное число?',
    note: 'Нужно использовать break',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: 'seq(4, 5, 1, 6)',
        output: 'false'
      },
      {
        input: 'seq(-3, -4)',
        output: 'true'
      },
      {
        input: 'seq(3, 7, -3, 7)',
        output: 'true'
      }
    ]
  },
  {
    component: Simple,
    name: 'Упорядоченность по возрастанию',
    text: 'Дана целочисленная последовательность \\(a\\). Упорядочена ли она по возрастанию?',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    note: null,
    examples: [
      {
        input: 'seq(1, 4, 5, 10)',
        output: 'true'
      },
      {
        input: 'seq(3, 4, 5, 4)',
        output: 'false'
      },
      {
        input: 'seq(3, 3, 4)',
        output: 'false'
      },
      {
        input: 'seq(-3, -2, -1)',
        output: 'true'
      }
    ]
  },
  {
    component: Simple,
    name: 'Упорядоченность по невозрастанию',
    text: 'Дана целочисленная последовательность \\(a\\). Упорядочена ли она по невозрастанию?',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    note: null,
    examples: [
      {
        input: 'seq(10, 5, 4, 1)',
        output: 'true'
      },
      {
        input: 'seq(4, 3, 2, 3)',
        output: 'false'
      },
      {
        input: 'seq(4, 3, 3)',
        output: 'true'
      },
      {
        input: 'seq(-3, -2, -1)',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Большие соседи',
    text: 'Дана целочисленная последовательность \\(a\\). Сколько в ней элементов, меньших своего правого соседа?',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Сколько таких элементов',
    note: null,
    examples: [
      {
        input: 'seq(4, 7, 9)',
        output: '2'
      },
      {
        input: 'seq(-3, -2, -4)',
        output: '1'
      },
      {
        input: 'seq(10)',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Каждый второй положительный',
    text: 'Дана целочисленная последовательность \\(a\\). Является ли каждый второй элемент положительным?',
    note: 'Решить, не используя счётчик',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: 'seq(-4, 7, -9)',
        output: 'true'
      },
      {
        input: 'seq(-3, 2, -4, -2)',
        output: 'false'
      },
      {
        input: 'seq(10, 10)',
        output: 'true'
      }
    ]
  },
  {
    component: Simple,
    name: 'Накопительная сумма',
    text: 'Дана целочисленная последовательность \\(a\\). Предположим, что есть некая последовательность \\(b\\), которая является накопительной суммой \\(a\\). То есть, \\(b_1 = a_1\\), \\(b_2 = a_1 + a_2\\), \\(b_3 = a_1 + a_2 + a_3\\) и т. д. Найдите сумму элементов последовательности \\(b\\)',
    note: 'Например, если \\(a\\) = [3, 2, 1], то \\(b\\) = [3, 3 + 2, 3 + 2 + 1] = [3, 5, 6]. Тогда сумма элементов \\(b\\) равна 14',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Сумма элементов \\(b\\)',
    examples: [
      {
        input: 'seq(2, 2, 3)',
        output: '13'
      },
      {
        input: 'seq(3, 2, 1)',
        output: '14'
      },
      {
        input: 'seq(1, 1, 1, 1)',
        output: '10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Возрастают через одного?',
    text: 'Дана целочисленная последовательность \\(a\\). Получится ли возрастающая последовательность, если брать только каждый второй элемент?',
    note: 'Не использовать счётчик',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: 'seq(-4, 7, -9)',
        output: 'true'
      },
      {
        input: 'seq(-3, 2, -4, -2)',
        output: 'false'
      },
      {
        input: 'seq(-1, 3, -2, 7, -3)',
        output: 'true'
      },
      {
        input: 'seq(-1, 3, -2, 7, -3, 5)',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Невозрастают через одного?',
    text: 'Дана целочисленная последовательность \\(a\\). Получится ли невозрастающая последовательность, если брать только каждый второй элемент, начиная с первого?',
    note: 'Не использовать счётчик',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: 'seq(1, 1, 1)',
        output: 'true'
      },
      {
        input: 'seq(5, 2, 2)',
        output: 'true'
      },
      {
        input: 'seq(-1, 3, 0, 7, -3)',
        output: 'false'
      },
      {
        input: 'seq(1, 3, 0, 7, 2)',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма с подменой знаков',
    text: 'Дана целочисленная последовательность \\(a\\). Найдите сумму элементов, меняя знак каждого второго элемента',
    note: 'Решить без if',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Выведите одно число - полученный результат',
    examples: [
      {
        input: 'seq(1, -1, 1)',
        output: '3'
      },
      {
        input: 'seq(5, 2, 2)',
        output: '5'
      },
      {
        input: 'seq(-1, 3, 0, 7, -3)',
        output: '-14'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество положительных и неположительных',
    text: 'Дана целочисленная последовательность \\(a\\). Найдите количество положительных и неположительных чисел',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Выведите два числа через пробел - количество положительных и количество неположительных чисел в последовательности',
    examples: [
      {
        input: 'seq(1, -1, 1)',
        output: '2 1'
      },
      {
        input: 'seq(5, 2, 2)',
        output: '3 0'
      },
      {
        input: 'seq(-1, 3, 0, 7, -3)',
        output: '2 3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первое чётное',
    text: 'Дана целочисленная последовательность \\(a\\). Найдите порядковый номер первого чётного числа. Если таких чисел нет, выведите 0',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Порядковый номер первого чётного',
    examples: [
      {
        input: 'seq(5, 2, 4)',
        output: '2'
      },
      {
        input: 'seq(2, 6, 4, 8)',
        output: '1'
      },
      {
        input: 'seq(7, 3, 1)',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма порядковых номеров',
    text: 'Дана целочисленная последовательность \\(a\\). Найдите сумму порядковых номеров чётных элементов. Если таких нет, выведите 0',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Сумма порядковых номеров',
    examples: [
      {
        input: 'seq(1, 2, 4)',
        output: '5'
      },
      {
        input: 'seq(2, 6, 4, 8)',
        output: '10'
      },
      {
        input: 'seq(7, 3, 1)',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Сумма номеров и элементов',
    text: 'Дана целочисленная последовательность \\(a\\). Найдите сумму порядковых номеров нечётных элементов и сумму самих этих элементов. Если таких нет, выведите два нуля',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Сумма номеров и сумму элементов через пробел',
    examples: [
      {
        input: 'seq(1, 2, 4)',
        output: '1 1'
      },
      {
        input: 'seq(7, 3, 1)',
        output: '6 11'
      },
      {
        input: 'seq(2, 6, 4, 8)',
        output: '0 0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Два максимума',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите два наибольших элемента в порядке невозрастания',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Выведите два числа через пробел - полученный результат',
    examples: [
      {
        input: 'seq(3, 1, 5, 2)',
        output: '5 3'
      },
      {
        input: 'seq(-1, -2, -3, -4)',
        output: '-1 -2'
      },
      {
        input: 'seq(4, 5, 3, 5)',
        output: '5 5'
      },
      {
        input: 'seq(6, 4, 5)',
        output: '6 5'
      }
    ]
  },
  {
    component: Simple,
    name: 'Самые большие соседи',
    text: 'Дана целочисленная последовательность \\(a\\). Выведите наибольшую сумму двух соседних элементов',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Наибольшая сумма соседей',
    examples: [
      {
        input: 'seq(3, 2, 5, 1)',
        output: '7'
      },
      {
        input: 'seq(-1, -2, -3, -4)',
        output: '-3'
      },
      {
        input: 'seq(3, 5, 4, 6)',
        output: '10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество в сериях',
    text: 'Дана целочисленная последовательность \\(a\\), состоящая из нулей и единиц. Выведите количество подряд идущих нулей для каждой серии из нулей, и количество подряд идущих единиц для каждой серии из единиц',
    note: null,
    input: '\\(a\\) - последовательность из нулей и единиц',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: 'seq(0, 1, 0, 1)',
        output: '1\n1\n1\n1'
      },
      {
        input: 'seq(1, 1, 1, 0)',
        output: '3\n1'
      },
      {
        input: 'seq(0, 0, 1, 1)',
        output: '2\n2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Количество серий',
    text: 'Дана целочисленная последовательность \\(a\\), состоящая из нулей и единиц. Выведите количество серий одинаковых цифр, идущих подряд. Одиночные элементы тоже считать сериями',
    input: '\\(a\\) - последовательность из единиц и нулей',
    output: 'Количество серий',
    note: null,
    examples: [
      {
        input: 'seq(0, 1, 0, 1)',
        output: '4'
      },
      {
        input: 'seq(1, 1, 1, 0)',
        output: '2'
      },
      {
        input: 'seq(0, 0, 0, 0)',
        output: '1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Длина наибольшей серии',
    text: 'Дана целочисленная последовательность \\(a\\), состоящая из нулей и единиц. Выведите длину самой большой серии одинаковых цифр, идущих подряд. Одиночные элементы тоже считать сериями',
    input: '\\(a\\) - последовательность из единиц и нулей',
    output: 'Длина наибольшей серии',
    note: null,
    examples: [
      {
        input: 'seq(0, 1, 0, 1)',
        output: '1'
      },
      {
        input: 'seq(1, 1, 1, 0)',
        output: '3'
      },
      {
        input: 'seq(0, 0, 0, 0)',
        output: '4'
      }
    ]
  },
  {
    component: Simple,
    name: 'Суммы всех предыдущих',
    text: 'Дана целочисленная последовательность \\(a\\). Проверьте, соблюдается ли закономерность, что второе и каждое следующее число равно сумме всех предыдущих элементов. Если соблюдается, выведите 0, а если нет - порядковый номер первого элемента, нарушающего закономерность',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Порядковый номер или 0',
    examples: [
      {
        input: 'seq(3, 3, 6, 12)',
        output: '0'
      },
      {
        input: 'seq(0, 0, 0, 0)',
        output: '0'
      },
      {
        input: 'seq(1, 1, 1, 1)',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Фибоначчи-подобная последовательность',
    text: 'Дана целочисленная последовательность \\(a\\). Проверьте, соблюдается ли закономерность, что третье и каждое следующее число равно сумме двух предыдущих элементов. Если соблюдается, выведите 0, а если нет - порядковый номер первого элемента, нарушающего закономерность',
    note: null,
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'Порядковый номер или 0',
    examples: [
      {
        input: 'seq(1, 1, 2, 3, 5)',
        output: '0'
      },
      {
        input: 'seq(5, 6, 11, 17, 18)',
        output: '5'
      },
      {
        input: 'seq(1, 1, 1, 1)',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Равны ли суммы через одного?',
    text: 'Дана целочисленная последовательность \\(a\\). Верно ли, что сумма всех элементов с чётными номерами равна сумме элементов с нечётными номерами?',
    note: 'Желательно решить без if',
    input: '\\(a\\) - последовательность из целых чисел',
    output: 'true или false',
    examples: [
      {
        input: 'seq(1, 1, 2, 7, 5)',
        output: 'true'
      },
      {
        input: 'seq(2, 3, 2, 3, 2)',
        output: 'true'
      },
      {
        input: 'seq(1, 1, 1, 1, 1)',
        output: 'false'
      }
    ]
  },
  {
    component: Simple,
    name: 'Хитрый подсчёт',
    text: 'Дана упорядоченная по невозрастанию целочисленная последовательность \\(a\\) и целое число \\(k\\). Найдите количество чисел, которые больше, либо равны элементу последовательности с номером \\(k\\), и при этом больше 0',
    note: null,
    input: '\\(a\\) - упорядоченная последовательность из целых чисел, k - целое положительное',
    output: 'Сколько таких чисел?',
    examples: [
      {
        input: 'seq(10, 9, 8, 7, 7, 7, 5, 5), 5',
        output: '6'
      },
      {
        input: 'seq(3, 2, 1, 0, 0), 5',
        output: '3'
      },
      {
        input: 'seq(0, 0, 0, 0), 2',
        output: '0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Позиция первой наибольшей серии',
    text: 'Дана целочисленная последовательность \\(a\\), состоящая из нулей и единиц. Выведите порядковый номер числа, с которого начинается первая самая длинная серия одинаковых цифр, идущих подряд. Одиночные элементы тоже считать сериями',
    note: null,
    input: '\\(a\\) - последовательность из единиц и нулей',
    output: 'Позиция первой наибольшей серии',
    examples: [
      {
        input: 'seq(0, 1, 0, 1)',
        output: '1'
      },
      {
        input: 'seq(0, 1, 1, 1)',
        output: '2'
      },
      {
        input: 'seq(1, 0, 1, 1)',
        output: '3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Позиция последней наибольшей серии',
    text: 'Дана целочисленная последовательность \\(a\\), состоящая из нулей и единиц. Выведите порядковый номер числа, с которого начинается последняя самая длинная серия одинаковых цифр, идущих подряд. Одиночные элементы тоже считать сериями',
    note: null,
    input: '\\(a\\) - последовательность из единиц и нулей',
    output: 'Позиция последней наибольшей серии',
    examples: [
      {
        input: 'seq(0, 1, 0, 1)',
        output: '4'
      },
      {
        input: 'seq(0, 1, 1, 1)',
        output: '2'
      },
      {
        input: 'seq(1, 0, 1, 1)',
        output: '3'
      },
      {
        input: 'seq(1, 1, 1, 0)',
        output: '1'
      }
    ]
  },
  {
    component: FloatCounter,
    label: '!'
  },
  {
    component: Simple,
    name: 'Сотни грамм конфет',
    text: 'Дано вещественное число \\(p\\) - цена 1 кг конфет, а также целое число \\(n\\). Выведите стоимость \\(0.1\\) кг конфет, затем \\(0.2\\) кг, и т. д. до \\(n * 0.1\\) кг конфет',
    note: 'Нужно избежать погрешности при вычислении',
    input: '\\(p\\) - вещественное, \\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '300, 3',
        output: '30\n60\n90'
      },
      {
        input: '199.75, 4',
        output: '19.975\n39.95\n59.925\n79.9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Шаги по 200 грамм',
    text: 'Дано вещественное число \\(p\\) - цена 1 кг конфет, а также целое число \\(n\\). Выведите стоимость \\(1.2\\) кг конфет, затем \\(1.4\\) кг и т. д. с шагом \\(0.2\\). Всего \\(n\\) чисел',
    note: 'Нужно избежать погрешности при вычислении',
    input: '\\(p\\) - вещественное, \\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '100, 3',
        output: '120\n140\n160'
      },
      {
        input: '149.5, 4',
        output: '179.4‬\n209.3\n239.2\n269.1'
      }
    ]
  },
  {
    component: Simple,
    name: '*4 и *7',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) первых чисел, заканчивающихся на 4 или 7',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '1',
        output: '4'
      },
      {
        input: '2',
        output: '4\n7'
      },
      {
        input: '4',
        output: '4\n7\n14\n17'
      }
    ]
  },
  {
    component: Simple,
    name: '*4 и *7 - накопительная сумма',
    text: 'Дано целое число \\(n\\). Выведите \\(n\\) чисел, являющихся накопительной суммой чисел, заканчивающихся на 4 или 7. То есть, первое число \\(4\\), затем \\(4+7\\), \\(4+7+14\\) и т. д',
    note: null,
    input: '\\(n\\) - целое',
    output: 'Каждое число на новой строке',
    examples: [
      {
        input: '1',
        output: '4'
      },
      {
        input: '2',
        output: '4\n11'
      },
      {
        input: '4',
        output: '4\n11\n25\n42'
      }
    ]
  }
]

/*
• ?while:, ?разложение на цифры, ?делители числа, ?разложение на множители
*/
