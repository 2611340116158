<template>
  <h3 class="text-center">Последовательности</h3>
  <Code lang="js" :code="`
    const seq = (...args) => {
        let i = 0
        return {
            hasNext() {
                return i < args.length
            },
            next() {
                if (i === args.length) {
                    throw new Error('Считано больше элементов, чем есть в последовательности')
                }
                return args[i++]
            }
        }
    }
  `" />
  <div class="mt">Пока не обязательно понимать как этот код работает. Достаточно понимать как использовать функцию <strong>seq</strong> и её результат</div>
  <Code lang="js" :code="`
    const a = seq(10, 20, 30)
    console.log(a.hasNext())
    console.log(a.next())
    console.log(a.next())
    console.log(a.next())
    console.log(a.hasNext())
  `" />
  <div class="mt">Результат:</div>
  <CodeResult :code="`
    true
    10
    20
    30
    false
  `" />
  <div class="mt">Можно перебрать последовательность с помощью цикла while</div>
  <Code lang="js" :code="`
    const a = seq(10, 20, 30)
    while (a.hasNext()) {
        console.log(a.next())
    }
  `" />
  <div class="mt">Результат:</div>
  <CodeResult :code="`
    10
    20
    30
  `" />
</template>

<script>
import Code from '@/components/ui/Code'
import CodeResult from '@/components/ui/CodeResult'

export default {
  components: { Code, CodeResult },
  props: ['task']
}
</script>
